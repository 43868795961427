import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "0.25px solid #ffffff2b",
    backgroundColor: "#0E1F41",
    "& p": {
      color: "#ffffffa1",
      fontSize: "12px",
      display: "flex",
      textAlign: "center",
    },
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Typography variant="body1">
          Copyright &copy; 2022 The Survivor Game. All Rights Reserved.
        </Typography>
      </Box>
    </>
  );
}

export default Footer;
