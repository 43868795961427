export const NetworkContextName = "Kovan Test Network";
export const ACTIVE_NETWORK = 42;
export const GalacticEagleContract=
  "0xA51C7B4F87d0959EC7C03BEee160e9E2135AefeC";
export const baseURI =
  "https://ipfs.io/ipfs/QmdP6VwefyuVTz3k7n2AcPv16HxJ3fz3zN23vAkJY9S6ST";

export const RPC_URL =
  "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

export const NetworkDetails = [
  {
    chainId: "0x2A",
    chainName: "Kovan Test Network",
    nativeCurrency: {
      name: "Kovan Test Network",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://kovan.etherscan.io"],
  },
];
